import 'css/themify-icons.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'owl.carousel/dist/assets/owl.carousel.min.css';
import 'owl.carousel/dist/assets/owl.theme.default.min.css';
import 'angular-lightgallery/dist/css/lightgallery.css';
import 'angular-toastr/dist/angular-toastr.css';
import 'css/magnific-popup.css';
import 'css/superfish.css';
import 'css/easy-responsive-tabs.css';
import 'css/style.css';
import 'css/loading.css';
import 'css/portfolio.styl';

import 'lodash';

import 'jquery';
// import 'bootstrap';
import 'magnific-popup';
import 'jquery-hoverintent';
import 'superfish';
import 'easy-responsive-tabs';
import 'fastclick';